import { Container, Stack } from '@mui/material';
import Page from '#/components/shared/ui/Page';
import Contact from '#/components/pages/ContactUs/Contact';

export default function ContactUs() {
  return (
    <Page title="Contact Us">
      <Stack
        sx={{
          py: 8,
          m: 'auto',
          minHeight: '100vh',
          justifyContent: 'center',
        }}
      >
        <Container component="main" sx={{}}>
          <Contact />
        </Container>
      </Stack>
    </Page>
  );
}
