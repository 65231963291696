import { Box, Card, Typography, Divider, Stack, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import {
  StyledFormControlLabel,
  StyledRadio,
  StyledRadioGroup,
} from './styles';
import { contactData } from './contactData';
import { RHFTextField, FormProvider } from '#/components/shared/hook-form';
import { useContactUs } from '#/api/userQueries';
import useLocales from '#/hooks/useLocales';

type FormValuesProps = {
  first_name: string;
  last_name: string;
  email: string;
  type: string;
  message: string;
};

export default function Contact() {
  const { translate } = useLocales();
  const { mutateAsync: contactUs } = useContactUs();

  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string().required('firstName is required'),
    last_name: Yup.string().required('lastName is required'),
    type: Yup.string().required('A choice is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Email must be a valid email address'),
    message: Yup.string().required('Please put a message'),
  });

  const defaultValues = {
    first_name: '',
    last_name: '',
    email: '',
    type: '',
    message: '',
  };

  const methods = useForm({
    resolver: yupResolver(ValidationSchema),
    defaultValues,
  });

  const { handleSubmit, control, formState, reset } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    await contactUs(data)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.message_test'))
        );
        reset();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography
          component="h1"
          variant="h2"
          gutterBottom
          textAlign="center"
          sx={{
            marginY: 7,
          }}
        >
          {String(translate('global.footer.contact'))}
        </Typography>

        <Card sx={{ p: 3 }}>
          <Stack sx={{ m: 1 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              {String(translate('global.contactUs.getLegalSupport'))}
            </Typography>
            <Divider />
          </Stack>

          <Typography sx={{ ml: 1 }}>
            {String(translate('global.contactUs.description'))}
          </Typography>

          <Stack direction="row" spacing={2} marginY={2}>
            <RHFTextField
              name="first_name"
              label={String(translate('global.formLabels.firstName'))}
            />
            <RHFTextField
              name="last_name"
              label={String(translate('global.formLabels.lastName'))}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <RHFTextField
              name="email"
              label={String(translate('global.formLabels.emailAddress'))}
            />
          </Stack>
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value } }) => (
              <StyledRadioGroup name="type" value={value} onChange={onChange}>
                <Stack
                  direction={{
                    xs: 'column',
                    md: 'row',
                  }}
                  spacing={2}
                  marginTop={2}
                  marginBottom={2}
                >
                  {contactData.map((serviceOption) => (
                    <StyledFormControlLabel
                      key={`type-${serviceOption.label}`}
                      value={serviceOption.value}
                      control={<StyledRadio />}
                      label={String(
                        translate(`global.services.${serviceOption.label}`)
                      )}
                    />
                  ))}
                </Stack>
              </StyledRadioGroup>
            )}
          />
          <RHFTextField
            name="message"
            multiline
            rows={5}
            placeholder={String(
              translate('global.contactUs.pleaseWriteMessage')
            )}
          />
          <Divider sx={{ mb: 3, mt: 3 }} />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button
              aria-label="submit"
              disabled={!formState.isValid}
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                mr: 1,
              }}
            >
              {String(translate('global.formLabels.submit'))}
            </Button>
          </Box>
        </Card>
      </FormProvider>
    </Box>
  );
}
